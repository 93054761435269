import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUploadFiles from 'api/mutations/useUploadFiles';
import useGetFiles from 'api/queries/useGetFiles';
import FileUploadInput from 'components/molecules/FileUploadInput';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { StageSpinner } from 'react-spinners-kit';
import { File } from 'types';

import { MB_150_IN_BYTES, GB_2_IN_BYTES } from './constants';
import { FileChip } from './FileChip';

const FileUploaderComponent = ({
  setUploadDisabled,
  setTotalUnprocessedDocs,
  caseId,
  isCaseCreator,
}: {
  setUploadDisabled: (disabled: boolean) => void;
  setTotalUnprocessedDocs: (n: number) => void;
  caseId: string;
  isCaseCreator?: boolean;
}) => {
  const [allFiles, setAllFiles] = useState<File[]>();

  const {
    data: filesResponse,
    refetch: refetchFiles,
    isLoading: isLoadingFiles,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetFiles(caseId);

  const { onUpload, handleRemoveFile, isUploading, fileRemoving, successfulUploads } = useUploadFiles({
    caseId,
    refetchFiles,
  });

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: Boolean(hasNextPage),
    onLoadMore: fetchNextPage,
  });

  useEffect(() => {
    if (filesResponse) {
      setAllFiles(filesResponse.pages.flatMap((page) => page.files));
      setTotalUnprocessedDocs(filesResponse.pages.flat()[0].totalCount);
    }
  }, [filesResponse, setTotalUnprocessedDocs]);

  // Todo this won't even work if we use pagination
  const sum = allFiles?.reduce((acc: number, file: File) => acc + (file?.size || 0), 0) || 0;
  const largeFiles = allFiles?.filter((file: File) => {
    return file.size > MB_150_IN_BYTES;
  });
  const sizeExceeded = sum > GB_2_IN_BYTES;
  const fileCountExceeded = (allFiles?.length || 0) > 1000;

  const getMessage = () => {
    if (largeFiles?.length && largeFiles?.length > 0) {
      if (sizeExceeded) {
        return 'Exceeded 2GB upload limit';
      }
      if (fileCountExceeded) {
        return 'Exceeded 1000 file count limit.';
      }

      return `${largeFiles?.length} files exceed 150MB file size limit`;
    }
    return '';
  };
  const message = getMessage();

  useEffect(() => {
    setUploadDisabled(
      isUploading ||
        sizeExceeded ||
        fileCountExceeded ||
        (allFiles?.length || 0) > 1000 ||
        (allFiles?.length || 0) === 0,
    );
  }, [allFiles, isUploading, sizeExceeded, fileCountExceeded, setUploadDisabled]);

  return (
    <div>
      {message !== '' && <div className="font-semibold italic text-red-700">{message}</div>}
      <FileUploadInput
        isUploading={isUploading}
        onDrop={onUpload}
        successfulUploads={successfulUploads}
        tooltipText="Supported file types: EML, MHT, DocX, Doc, PDF, and Spreadsheet files (more coming soon). Max file size is 150MB."
      />
      <div className="flex-column flex w-full">
        {isLoadingFiles ? (
          <div className={`flex h-32 w-full items-center justify-center ${isCaseCreator ? 'h-96' : 'h-32'}`}>
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        ) : (
          <>
            {allFiles?.length && allFiles?.length > 0 ? (
              <div
                className={`mt-2 flex w-full flex-col justify-between overflow-y-scroll rounded border bg-gray-50 p-2 ${
                  isCaseCreator ? 'h-80' : 'h-64'
                }`}
              >
                <div className="flex flex-wrap justify-between gap-2">
                  {allFiles?.map((file, idx) => {
                    const fileSizeExceeded = file.size > MB_150_IN_BYTES;
                    return (
                      <FileChip
                        key={file.id}
                        file={file}
                        fileSizeExceeded={fileSizeExceeded}
                        removing={fileRemoving === file.id}
                        index={idx}
                        handleRemoveFile={handleRemoveFile}
                      />
                    );
                  })}
                </div>
                {hasNextPage && !isFetchingNextPage && (
                  <div ref={sentryRef} className="my-2 flex items-center justify-center">
                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary" />
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
export default FileUploaderComponent;
