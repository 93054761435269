import React, { useEffect, useRef, useState } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import useUpdateMatter from 'api/mutations/useUpdateMatter';
import useGetMatter from 'api/queries/useGetMatter';
import useOrgUsers from 'api/queries/useGetOrgUsers';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { MyOptionType, OrgUserOption } from 'types';

import CreateMatterButton from './components/CreateMatterButton';
import TextAreaWithLabel from './components/TextAreaWithLabel';
import UserList from './components/UserList';

const MatterCreator: React.FC = () => {
  const navigate = useNavigate();
  const { matterId } = useParams<{ matterId: string }>(); // Get matterId from URL
  const [matterName, setMatterName] = useState<string>('');
  const [matterDescription, setMatterDescription] = useState<string>('');
  const [matterCode, setMatterCode] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<OrgUserOption[]>([]);
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaDescriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaMatterCodeRef = useRef<HTMLTextAreaElement | null>(null);
  const authInfo = useAuthInfo();

  const onSuccess = () => {
    toast.success('Matter updated successfully');
    navigate(`/app/chronos/matters?page=0`);
  };

  const { data: orgUsers } = useOrgUsers();
  const { mutate: updateMatter, isLoading: isLoadingUpdate, data: updateResponse } = useUpdateMatter(onSuccess);
  const { isLoadingMatter, responseMatterData, refetchMatterData } = useGetMatter(matterId || '');

  useEffect(() => {
    if (matterId) {
      refetchMatterData();
    }
  }, [matterId, refetchMatterData]);

  // Pre-fill form when data is fetched
  useEffect(() => {
    if (responseMatterData) {
      setMatterName(responseMatterData.name);
      setMatterDescription(responseMatterData.description);
      setMatterCode(responseMatterData.code);
      setSelectedUsers(
        responseMatterData.users.map((x) => {
          return {
            id: x.id,
            email: x.email,
          };
        }),
      );
    }
  }, [responseMatterData]);

  useEffect(() => {
    if (updateResponse?.matterUpdate) {
      navigate(`/app/chronos/explore?matterId=${updateResponse?.matterUpdate?.matter_id}`);
    }
  }, [navigate, updateResponse]);

  const handleChangeMatterName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterName(e.target.value);
  };

  const handleChangeMatterDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterDescription(e.target.value);
  };

  const handleChangeMatterCode = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterCode(e.target.value);
  };

  const handleUpdateMatter = () => {
    if (matterId === undefined) {
      return;
    }
    updateMatter({
      id: matterId || '',
      name: matterName,
      description: matterDescription,
      code: matterCode,
      users: selectedUsers,
    });
  };

  const handleClickBack = () => {
    navigate(`/app/chronos/explorer/matters`);
  };

  const handleRemoveUser = (userId: string) => {
    const indexToRemove = selectedUsers.findIndex((x) => x.id === userId);
    const arrayCopy = [...selectedUsers];
    arrayCopy.splice(indexToRemove, 1);
    setSelectedUsers(arrayCopy);
  };

  const handleChangeSelectedUsers = (userToAdd: MyOptionType) => {
    const newSelectedUsers = [
      ...selectedUsers,
      {
        id: userToAdd?.value || '',
        email: userToAdd?.label || '',
      },
    ];
    setSelectedUsers(newSelectedUsers);
  };

  const canCreateMatter = matterName && matterDescription && selectedUsers;

  const userId = authInfo?.user?.userId;
  const selectedIds = selectedUsers.map((x) => x.id);
  const orgUsersOptions = (orgUsers ?? [])
    .filter((u) => !selectedIds.includes(u.id) && u.id !== userId)
    .map((u) => ({
      value: u.id,
      label: u.email,
    }));

  return (
    <div className="relative flex w-full flex-row overflow-auto text-white">
      {isLoadingMatter ? (
        <div className="flex h-full w-full items-center justify-center">
          <StageSpinner className="m-auto" size={25} color={'#081D57'} />
        </div>
      ) : (
        <>
          <div className="flex w-full flex-col items-start justify-start pl-8">
            <div className="mt-4 flex flex-row items-center justify-start">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 h-6 w-6 cursor-pointer text-black"
                onClick={handleClickBack}
              />
              <div className="w-full text-xl font-bold not-italic text-black">Update Matter</div>
            </div>
            <div className="" style={{ width: '690px' }}>
              <TextAreaWithLabel
                ref={textAreaNameRef}
                value={matterName}
                onChange={handleChangeMatterName}
                label="Name of Matter"
              />
              <TextAreaWithLabel
                ref={textAreaDescriptionRef}
                value={matterDescription}
                onChange={handleChangeMatterDescription}
                label="Description"
              />
              <TextAreaWithLabel
                ref={textAreaMatterCodeRef}
                value={matterCode}
                onChange={handleChangeMatterCode}
                label="Matter Code (optional)"
              />

              <div className="mt-5 w-full text-lg font-bold not-italic text-black">Members ({selectedIds.length})</div>

              <Select
                className={`mr-16 w-full rounded-md bg-white outline-none`}
                styles={reactSelectStylesCaseCreator}
                onChange={handleChangeSelectedUsers}
                options={orgUsersOptions}
                value={[]}
                placeholder="Select team members to include"
              />
              <UserList users={selectedUsers} onRemove={handleRemoveUser} />
            </div>
            <div className="mt-8">
              <CreateMatterButton
                isLoading={isLoadingUpdate || isLoadingMatter}
                isDisabled={!canCreateMatter}
                onClick={handleUpdateMatter}
                text={'Update Matter'}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MatterCreator;
