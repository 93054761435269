import { useState } from 'react';

import { faClock, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Case } from 'types';

import RunsView from './RunsView';

interface PipelineCompleteOverviewProps {
  totalRuns: number;
  caseState: Case | null | undefined;
}

const PipelineCompleteOverview = ({ caseState, totalRuns }: PipelineCompleteOverviewProps) => {
  const BORDER_STYLES = !!totalRuns ? 'border-yellow-600' : 'border-green-500';
  const TEXT_STYLES = !!totalRuns ? 'text-yellow-600' : 'text-green-500';

  const [open, setOpen] = useState(false);

  return (
    <div
      className={
        'relative flex cursor-pointer flex-row items-center justify-center rounded-lg border border-solid bg-white px-5 py-2 text-sm text-black shadow-sm ' +
        BORDER_STYLES
      }
      onClick={() => setOpen(!open)}
    >
      <FontAwesomeIcon icon={faClock} className={TEXT_STYLES} />
      <div className={'mx-4 font-semibold ' + TEXT_STYLES}>
        {!!totalRuns ? `${totalRuns} ${totalRuns === 1 ? 'Run' : 'Runs'} In Progress` : 'All Runs Complete'}
      </div>
      <FontAwesomeIcon
        className={'transition-transform duration-500 ' + TEXT_STYLES}
        style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
        icon={faChevronDown}
      />
      <div
        className={`absolute right-0 top-12 z-10 overflow-scroll transition-all duration-500 ${
          open ? 'max-h-80' : 'max-h-0'
        }`}
      >
        <RunsView runs={caseState?.runs || []} />
      </div>
    </div>
  );
};

export default PipelineCompleteOverview;
