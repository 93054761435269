import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { trackPageView } from 'analytics/Mixpanel';
import { useGetDocs } from 'api/queries/useGetDocs';
import { useGetDocTotals } from 'api/queries/useGetDocTotals';
import { useSearchParams } from 'react-router-dom';
import { Doc } from 'types';

import DocumentRow from './components/DocumentRow';
import DocumentEditorToolbar from './DocumentEditorToolbar';
import DocViewer from '../../components/DocViewer/FullDocViewer';

const DocumentEditor = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') || '0';
  const docPageNumber = Number(searchParams.get('docPageNumber'));
  const docId = searchParams.get('docId');

  const [globalFilter, setGlobalFilter] = useState('');
  const [appliedGlobalFilter, setAppliedGlobalFilter] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [title, setTitle] = useState('');
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isDocViewerFullyOpen, setIsDocViewerFullyOpen] = useState(false);

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
      // Add a slight delay before setting isDocViewerFullyOpen to true
      setTimeout(() => setIsDocViewerFullyOpen(true), 300);
    } else {
      setIsDocViewerFullyOpen(false);
      // Add a slight delay before closing the doc viewer
      setTimeout(() => setIsDocViewerOpen(false), 300);
    }
  }, [docId]);

  const {
    data: responseDocs,
    isFetching: isFetchingDocsTable,
    isLoading: isLoadingDocsTable,
    refetch: refetchDocs,
  } = useGetDocs(caseId, pagination.pageIndex + 1, pagination.pageSize, appliedGlobalFilter);
  const { data: docTotals } = useGetDocTotals(caseId);

  const onSearchCall = () => {
    setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    setAppliedGlobalFilter(globalFilter);
  };

  const clearSearch = async () => {
    setGlobalFilter('');
    setAppliedGlobalFilter('');
  };

  useEffect(() => {
    refetchDocs();
  }, [pagination, appliedGlobalFilter, refetchDocs]);

  useEffect(() => {
    trackPageView('Documents Page');
  }, []);

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  return (
    <div className="flex h-full w-full">
      {!isDocViewerFullyOpen && (
        <div
          className="flex h-full flex-col transition-all duration-300 ease-in-out"
          style={{ width: isDocViewerOpen ? '0%' : '100%', opacity: isDocViewerOpen ? 0 : 1 }}
        >
          <DocumentEditorToolbar
            processedCount={docTotals?.processed ?? 0}
            totalDocs={docTotals?.total ?? 0}
            openSettingsModal={openSettingsModal}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            onSearchCall={onSearchCall}
            clearSearch={clearSearch}
            totalPages={responseDocs?.pageCount || 0}
            goToPage={goToPage}
            currentPage={pagination.pageIndex}
            prevPage={prevPage}
            nextPage={nextPage}
          />
          <div className="mb-6 overflow-y-scroll">
            {isLoadingDocsTable ||
              (isFetchingDocsTable && (
                <div className="flex h-full min-h-[500px] w-full items-center justify-center">
                  <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
                </div>
              ))}
            <div className="flex w-full flex-col gap-3 py-1">
              {responseDocs &&
                responseDocs.docs &&
                responseDocs.docs.length > 0 &&
                responseDocs.docs.map((doc: Doc) => {
                  return <DocumentRow key={doc.id} doc={doc} caseId={caseId || ''} setTitle={setTitle} />;
                })}
            </div>
          </div>
        </div>
      )}

      <div
        className={`transition-all duration-300 ease-in-out ${
          isDocViewerOpen ? 'w-full translate-x-0 opacity-100' : 'w-0 translate-x-full opacity-0'
        } overflow-hidden bg-white`}
      >
        {docId && (
          <DocViewer
            title={title}
            docId={docId}
            caseId={caseId || ''}
            pageNumber={docPageNumber}
            factTotal={responseDocs?.docs?.find((doc) => doc.id === docId)?.factsCount?.toString() ?? null}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentEditor;
