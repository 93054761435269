import Button from 'components/atoms/Button';
import { useUserContext } from 'Contexts/User';
import formatDate from 'helpers/formatDate';
import { cn } from 'helpers/shadCnUtils';
import { Case } from 'types';

interface CaseCardProps {
  caseObject: Case;
  onClick: (itemId: string, status?: string) => void;
  onDelete: (itemId: string) => void;
  isDeleting: boolean;
}

const DraftCaseCard = ({
  caseObject,
  onClick,
  onDelete,
  isDeleting,
  canDelete,
}: CaseCardProps & { canDelete: boolean }) => {
  return (
    <div className="flex h-full flex-col items-start justify-between rounded-md border border-yellow-500 border-opacity-30 bg-gray-50 px-6 py-4 opacity-80 shadow">
      <div className="w-full">
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex items-center justify-between overflow-hidden text-sm font-semibold not-italic leading-5 text-green-700">
            Created: {`${formatDate(caseObject.createdDate)}`}
          </div>
          <div className="flex h-5 items-center rounded bg-brandTertiary bg-opacity-20 px-2 text-xs font-normal text-gray-500">
            Draft
          </div>
        </div>

        <div className="mt-2 text-lg not-italic text-gray-500">{caseObject.name || 'Case name'}</div>
      </div>
      <div>
        <div className="mt-4 flex gap-4">
          <Button
            rounded="md"
            size="xs"
            text="Edit"
            type="primary"
            disabled={isDeleting}
            onClick={() => onClick(caseObject.id)}
          />
          {canDelete && (
            <Button
              rounded="md"
              size="xs"
              text="Delete Case"
              type="delete"
              loading={isDeleting}
              onClick={() => onDelete(caseObject.id)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const CaseCard = ({ caseObject, onClick, onDelete, isDeleting }: CaseCardProps) => {
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';
  const isOwner = user?.id === caseObject.createdById;

  const incompleteCase = caseObject.runs.length === 0;
  const canDelete = isSuperAdmin || isOwner;
  if (incompleteCase) {
    return (
      <DraftCaseCard
        caseObject={caseObject}
        onClick={onClick}
        onDelete={onDelete}
        isDeleting={isDeleting}
        canDelete={canDelete}
      />
    );
  }

  return (
    <div className={cn('flex h-full flex-col items-start justify-start rounded-md border bg-white px-6 py-4 shadow')}>
      <div className="flex w-full flex-col justify-between">
        <div className="flex w-full flex-row items-center justify-between">
          <div className="right-0 flex items-center justify-between overflow-hidden text-sm font-semibold not-italic leading-5 text-green-700">
            Created: {`${formatDate(caseObject.createdDate)}`}
          </div>
          {caseObject.disputeStatus && (
            <div className="flex h-5 items-center rounded bg-brandTertiary bg-opacity-20 px-2 text-xs font-normal text-gray-500">
              {caseObject.disputeStatus}
            </div>
          )}
        </div>

        <div className="mt-2 text-lg font-semibold not-italic">{caseObject.name}</div>

        <div className="text-xs text-gray-600">
          {caseObject.legalIssues?.length > 100 ? (
            `${caseObject.legalIssues.slice(0, 100)}...`
          ) : (
            <p>{caseObject.legalIssues}</p>
          )}
        </div>

        <div>
          <div className="mt-4 flex gap-4">
            <Button
              rounded="md"
              size="xs"
              text="View"
              type="primary"
              disabled={isDeleting}
              onClick={() => onClick(caseObject.id, caseObject.runs[0].status)}
            />
            {canDelete && (
              <Button
                rounded="md"
                size="xs"
                text="Delete Case"
                type="delete"
                loading={isDeleting}
                onClick={() => onDelete(caseObject.id)}
              />
            )}
          </div>
          {caseObject.editedBy?.email && caseObject.editedDate && (
            <div className="mt-3 flex w-fit items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs font-normal italic leading-4 text-gray-800">
              {`Last updated by ${caseObject.editedBy?.email} on ${formatDate(caseObject.editedDate)}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseCard;
