import { useRef } from 'react';

import { faChartSimple, faGrip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Filters from 'components/molecules/UsageFilters';
import { MultiValue } from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { OrgUser, Matter, MyOptionType, Org } from 'types';

import { UsageViewType } from '../..';

interface UsageHeaderProps {
  isFetching: boolean;
  isWexlerAdmin: boolean;
  view: UsageViewType;
  mattersData: any;
  usersData: any;
  orgData?: Org[];
  selectedOrganisations: MultiValue<MyOptionType>;
  selectedMatters: MultiValue<MyOptionType>;
  selectedUsers: MultiValue<MyOptionType>;
  setView: (viewType: UsageViewType) => void;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setSelectedMatters: (value: MultiValue<MyOptionType>) => void;
  setSelectedUsers: (value: MultiValue<MyOptionType>) => void;
  setSelectedOrganisations: (value: MultiValue<MyOptionType>) => void;
  handleDownloadClick: VoidFunction;
  startDate: Date | null;
  endDate: Date | null;
}

const UsageHeader = ({
  mattersData,
  orgData,
  usersData,

  view,
  startDate,
  endDate,
  selectedOrganisations,
  selectedMatters,
  selectedUsers,

  setStartDate,
  setEndDate,
  setView,
  setSelectedOrganisations,
  setSelectedMatters,
  setSelectedUsers,
  handleDownloadClick,
  isWexlerAdmin,
  isFetching,
}: UsageHeaderProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const userOptions =
    usersData?.map((user: OrgUser) => ({
      label: user.email,
      value: user.id,
    })) || [];

  const matterOptions =
    mattersData?.map((matter: Matter) => ({
      label: selectedOrganisations.length > 0 ? matter.name : matter.id,
      value: matter.id,
    })) || [];

  const organisationOptions =
    orgData?.map((org) => ({
      label: org.name,
      value: org.id,
    })) || [];

  return (
    <div className="flex items-start justify-between">
      <div className="mr-auto flex items-center justify-center text-sm text-gray-700">
        <button
          onClick={() => setView('charts')}
          className={
            `flex items-center justify-center gap-1 rounded-tl-lg border px-4 py-2 ` +
            `rounded-bl-lg hover:border-brandSecondary hover:text-brandSecondary` +
            `${classNames({ 'border-brandSecondary text-brandSecondary': view === 'charts' })}`
          }
        >
          <FontAwesomeIcon icon={faGrip} />
          Charts
        </button>
        <button
          onClick={() => setView('table')}
          className={
            `flex items-center justify-center gap-1 rounded-tr-lg border px-4 py-2 ` +
            `rounded-br-lg hover:border-brandSecondary hover:text-brandSecondary` +
            `${classNames({ 'border-brandSecondary text-brandSecondary': view === 'table' })}`
          }
        >
          <FontAwesomeIcon icon={faChartSimple} />
          Table
        </button>
      </div>

      <Filters
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={setStartDate}
        onChangeEndDate={setEndDate}
        userOptions={userOptions}
        selectedUsers={selectedUsers}
        onChangeSelectedUsers={setSelectedUsers}
        matterOptions={matterOptions}
        selectedMatters={selectedMatters}
        onChangeSelectedMatters={setSelectedMatters}
        organisationOptions={isWexlerAdmin ? organisationOptions : undefined}
        selectedOrganisations={isWexlerAdmin ? selectedOrganisations : undefined}
        onChangeSelectedOrganisations={isWexlerAdmin ? setSelectedOrganisations : undefined}
      />
      <div className="relative ml-2" ref={wrapperRef}>
        <div
          className={`flex w-28 cursor-pointer items-center justify-center rounded bg-brandSecondary px-2 py-2 font-semibold text-white ${
            isFetching ? 'cursor-not-allowed' : ''
          }`}
          onClick={!isFetching ? handleDownloadClick : undefined}
          data-tooltip-id="download-tooltip"
        >
          {isFetching ? <StageSpinner className="m-auto" size={25} color={'white'} /> : 'Download'}
        </div>
      </div>
    </div>
  );
};

export default UsageHeader;
