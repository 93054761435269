import { useEffect, useMemo, useState } from 'react';

import { faAdd, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreateCase } from 'api/mutations/useCreateCase';
import { useDeleteCase } from 'api/mutations/useDeleteCase';
import { useCasesQuery } from 'api/queries/useGetCases';
import useGetMatter from 'api/queries/useGetMatter';
import Button from 'components/atoms/Button';
import { BreadcrumbHeading } from 'components/molecules/Breadcumb';
import EmptyState from 'components/molecules/EmptyState';
import ConfirmationModal from 'components/molecules/Modals/Confirmation';
import SearchBox from 'components/molecules/SearchBox';
import Pagination from 'components/organisms/Pagination';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Case } from 'types';

import CaseCard from './components/CaseCard';

const CasesContent = ({
  cases,
  onDelete,
  caseIdToDelete,
}: {
  cases?: Case[];
  caseIdToDelete: string;
  onDelete: (caseId: string) => void;
}) => {
  const navigate = useNavigate();

  const activeCases = useMemo(() => cases?.filter((chronosCase) => chronosCase.runs.length > 0), [cases]) || [];
  const draftCases = useMemo(() => cases?.filter((chronosCase) => chronosCase.runs.length === 0), [cases]) || [];

  const handleViewCase = (id: string, pipeline_status?: string) => {
    // Don't navigate to summary page if it's not ready yet
    if (!pipeline_status || [PIPELINE_STATUS.draft.value].includes(pipeline_status)) {
      navigate(`/app/chronos/case-creator?caseId=${id}`);
    } else {
      navigate(`/app/chronos/case-editor/summary?caseId=${id}`);
    }
  };

  if (!cases || cases.length === 0) {
    return <EmptyState title="No existing cases found" subtitle="Create your first case!" />;
  }
  return (
    <div className="flex w-full flex-col flex-wrap items-stretch gap-6 px-4">
      <p className="border-b-1 relative w-fit border border-x-0 border-t-0 border-gray-300">Active cases</p>
      <div className="flex w-full flex-wrap items-stretch gap-6">
        {activeCases.map((caseObject) => {
          return (
            <div key={caseObject.id} className="w-[calc(33.33%-1rem)]">
              <CaseCard
                caseObject={caseObject}
                onClick={handleViewCase}
                isDeleting={caseIdToDelete === caseObject.id}
                onDelete={onDelete}
              />
            </div>
          );
        })}
      </div>
      <p className="border-b-1 relative w-fit border border-x-0 border-t-0 border-gray-300">Draft cases</p>
      <div className="flex w-full flex-wrap items-stretch gap-6">
        {draftCases.map((caseObject) => {
          return (
            <div key={caseObject.id} className="w-[calc(33.33%-1rem)]">
              <CaseCard
                caseObject={caseObject}
                onClick={handleViewCase}
                isDeleting={caseIdToDelete === caseObject.id}
                onDelete={onDelete}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CasesExplorer = () => {
  // State
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [caseIdToDelete, setCaseIdToDelete] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const pageQuery = parseInt(searchParams.get('page') || '0') || 0;
  const matterId = searchParams.get('matterId');

  const {
    isLoading: isLoadingCases,
    data: casesData,
    refetch: refetchCases,
  } = useCasesQuery({ matterId, searchText, pageQuery });

  const { isLoadingMatter, responseMatterData } = useGetMatter(matterId ?? '');

  const { mutate: createCase, isLoading: isCreating } = useCreateCase();

  const onDeleteSuccess = (data: Response) => {
    if (data.ok) {
      toast.success('Case deleted successfully');
    } else {
      toast.error('Error deleting case. Try again later');
    }
    setConfirmDeleteModalOpen(false);
    setCaseIdToDelete('');
    refetchCases();
  };

  const onDeleteError = () => {
    toast.error('Error deleting case. Try again later');
    setConfirmDeleteModalOpen(false);
    setCaseIdToDelete('');
  };

  const onDelete = (caseId: string) => {
    setCaseIdToDelete(caseId);
    setConfirmDeleteModalOpen(true);
  };

  const { mutate: deleteCase, isLoading: isDeleting } = useDeleteCase({
    onError: onDeleteError,
    onSuccess: onDeleteSuccess,
  });

  useEffect(() => {
    setTotalPages(casesData?.pageCount || 1);
  }, [casesData]);

  // Handlers
  const handleGoToPreviousPage = () => {
    navigate(
      `/app/chronos/explore?matterId=${matterId}&page=${pageQuery - 1}${searchText ? `&searchText=${searchText}` : ''}`,
    );
  };

  const handleGoToNextPage = () => {
    navigate(
      `/app/chronos/explore?matterId=${matterId}&page=${pageQuery + 1}${searchText ? `&searchText=${searchText}` : ''}`,
    );
  };

  const goToPage = (value: number) => {
    navigate(`/app/chronos/explore?matterId=${matterId}&page=${value}${searchText ? `&searchText=${searchText}` : ''}`);
  };

  const onDeleteConfirm = () => {
    setConfirmDeleteModalOpen(false);
    deleteCase(caseIdToDelete);
  };

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="min-h-0 w-full overflow-y-auto px-6 py-4">
        <div className="flex w-full flex-col gap-2 text-gray-800">
          <div className="mt-1 w-full text-2xl font-semibold not-italic text-gray-900">
            <BreadcrumbHeading
              pages={[
                {
                  label: <FontAwesomeIcon icon={faHome} className="h-4 w-4 text-gray-700" />,
                  href: '/app/chronos/matters',
                },
                {
                  label: <div>{isLoadingMatter ? '...' : responseMatterData?.name}</div>,
                  href: `/app/chronos/explore?matterId=${matterId}`,
                },
              ]}
            />
          </div>

          <div className="relative mb-2 flex h-full min-h-[calc(100vh-6rem)] w-full rounded-lg border-2 border-gray-200 border-opacity-40 bg-white">
            <div className="w-full py-4">
              <div className="mb-4 flex w-full flex-col">
                <h2 className="mb-4 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">Cases</h2>
                <div className="flex items-stretch justify-between gap-4 px-4">
                  <div className="flex flex-row items-center gap-6">
                    <SearchBox value={searchText} onChange={setSearchText} placeholder="Search cases..." />

                    <Button
                      type="brand"
                      rounded="base"
                      icon={<FontAwesomeIcon icon={faAdd} className="mr-2 text-white" />}
                      text="New Case"
                      onClick={() => createCase(matterId)}
                      disabled={isCreating}
                    />
                  </div>

                  {!isLoadingCases && casesData?.cases?.length ? (
                    <Pagination
                      canGetNextPage={pageQuery < totalPages - 1}
                      canGetPrevPage={pageQuery > 0}
                      prevPage={handleGoToPreviousPage}
                      nextPage={handleGoToNextPage}
                      currentPage={pageQuery}
                      noOfPages={totalPages}
                      goToPage={goToPage}
                    />
                  ) : null}
                </div>
              </div>

              {isLoadingCases ? (
                <div className="flex w-full flex-row flex-wrap items-start gap-6 px-4">
                  {Array(6)
                    .fill(null)
                    .map((_, index) => (
                      <div
                        key={`skeleton-${index}`}
                        className="h-56 w-[calc(33.33%-1rem)] animate-pulse rounded-md bg-gray-100"
                      />
                    ))}
                </div>
              ) : (
                <CasesContent cases={casesData?.cases} onDelete={onDelete} caseIdToDelete={caseIdToDelete} />
              )}
            </div>

            <ConfirmationModal
              title="Delete Case"
              description="Deleting cases is irreversible. Are you sure you want to proceed?"
              isLoading={isDeleting}
              isOpen={confirmDeleteModalOpen}
              handleClose={() => setConfirmDeleteModalOpen(false)}
              onConfirm={onDeleteConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesExplorer;
