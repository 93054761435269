import { useCallback, useEffect, useRef, useState } from 'react';

import { HighlightArea } from '@react-pdf-viewer/highlight';
import { trackPageView } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import PDFViewer from 'components/organisms/PDFViewer';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Location } from 'types';

const DocViewer = ({
  caseId,
  docId,
  eventId,
  pageNumber,
}: {
  caseId: string;
  docId: string;
  eventId: string;
  pageNumber: number;
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loadingFile, setLoadingFile] = useState(true);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState<(HighlightArea & { factId: string })[]>([]);

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching
  const getDocumentUrl = useCallback(async () => {
    if (!docId) return null; // Early exit if no docId provided

    try {
      const downloadResult = await fetch(`${APIBaseChronos}/api/case/${caseId}/docs/${docId}`, fetchConfigGET);
      if (!downloadResult.ok) throw new Error('Download failed');

      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob);
      // Consider where and how to call URL.revokeObjectURL(fileBlobUrl) for cleanup
      return fileBlobUrl;
    } catch (error) {
      console.error(error);
      return null; // Consider a structured error handling approach
    }
  }, [docId, fetchConfigGET, caseId]); // Add all dependencies here

  const { refetch: fetchDocument } = useQuery(['downloadDoc', docId], getDocumentUrl, { enabled: false });

  async function getFactLocation(): Promise<Location | undefined> {
    const fact = await fetch(`${APIBaseChronos}/api/case/${caseId}/fact/${eventId}`, fetchConfigGET).then((res) => {
      return res.json();
    });

    return fact.locations?.find((loc: Location) => loc.docId === docId && loc.factId === eventId);
  }

  const { data: factLocation } = useQuery({
    queryKey: ['factLocation', docId, eventId],
    queryFn: getFactLocation,
  });

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('docId');
    searchParams.delete('eventId');
    searchParams.delete('docPageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  // Effects
  useEffect(() => {
    if (docId) {
      setLoadingFile(true);
      fetchDocument().then(({ data: documentUrl }) => {
        if (documentUrl && typeof documentUrl === 'string') {
          setFileUrl(documentUrl);
        }
        setLoadingFile(false);
      });
    }
  }, [docId, fetchDocument]);

  useEffect(() => {
    if (docId && factLocation && factLocation.boundingBoxes.length > 0) {
      setMinPage(factLocation.boundingBoxes[0].pageNumber);
      // setDocuments(docIdsRelatedToEvent);

      const coordinates = factLocation.boundingBoxes.map((b) => ({
        width: b.width * 100,
        height: b.height * 100,
        top: b.top * 100,
        left: b.left * 100,
        pageIndex: b.pageNumber - 1,
        factId: eventId,
      }));
      setHighlightCoordinates(coordinates);
    }
  }, [factLocation, docId, eventId]);

  trackPageView('Doc viewer');

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        {fileUrl === 'download-error' ? (
          'File cannot be displayed, please try again later.'
        ) : (
          <div ref={viewerRef} className="h-full">
            <PDFViewer
              fileUrl={fileUrl}
              loading={loadingFile}
              initialPage={pageNumber || minPage}
              highlights={highlightCoordinates}
              handleClosePDFViewer={handleCloseDocDetailView}
              closeButtonText="Close"
              defaultZoom={0.8}
              type="inline"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocViewer;
