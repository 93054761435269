import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MatterEdit } from 'types';

// Assuming matterId is a string and fetchConfigGET is your fetch configuration for GET requests
const useGetMatter = (id: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const navigate = useNavigate();

  const getMatterData = async () => {
    const response = await fetch(`${APIBaseChronos}/api/matters/${id}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching matter data failed');
    }
    // Check if the user is not authorised to view this matter
    if (response.status === 403) {
      toast.error('Unauthorized. You are being redirected to the matters explorer.');
      navigate(`/app/chronos/matters`);
    }

    return response.json();
  };

  const {
    isFetching: isLoadingMatter,
    data: responseMatterData,
    refetch: refetchMatterData,
    error,
  } = useQuery<MatterEdit, Error>(['matterDataResult', id], getMatterData, {
    enabled: !!id, // Only run the query if the matterId is provided
  });

  return {
    isLoadingMatter,
    responseMatterData,
    refetchMatterData,
    error,
  };
};

export default useGetMatter;
