import { useCallback, useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { UNSUPPORTED_FILE_TYPES } from 'components/organisms/DocumentAdder/constants';
import { UseQueryResult } from 'react-query';
import Swal from 'sweetalert2';

class UploadError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

interface UseUploadFiles {
  caseId: string;
  refetchFiles: UseQueryResult['refetch'];
  forbiddenFileTypes?: string[];
}

const useUploadFiles = ({ caseId, refetchFiles, forbiddenFileTypes = UNSUPPORTED_FILE_TYPES }: UseUploadFiles) => {
  const [successfulUploads, setSuccessfulUploads] = useState(0);
  const [fileRemoving, setFileRemoving] = useState('');
  const [isUploading, setisUploading] = useState(false);
  const { getFetchConfig } = useGetFetchConfig();

  const uploadFile = useCallback(
    async (file: File): Promise<void> => {
      if (!caseId) return;

      if (forbiddenFileTypes.includes(file.name.slice(-4))) {
        Swal.fire({
          title: 'Unsupported File Type',
          text: `The file ${file.name} is not supported. Please upload supported file types.`,
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('caseId', caseId);

        const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });
        const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/docs`, fetchConfig);

        if (!response.ok) {
          const errorData = await response.json();
          throw new UploadError(errorData.message || 'Upload failed', response);
        }
      } catch (error) {
        if (error instanceof UploadError) {
          Swal.fire({
            title: 'Error',
            text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
    [caseId, getFetchConfig, forbiddenFileTypes],
  );

  const handleRemoveFile = async (fileId: string) => {
    setFileRemoving(fileId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/api/files/${fileId}`, fetchConfig);
    await refetchFiles();
    setFileRemoving('');
  };

  const onUpload = useCallback(
    async (acceptedFiles: File[]) => {
      setisUploading(true);
      setSuccessfulUploads(0);

      const uploadPromises = acceptedFiles.map(async (file) => {
        try {
          await uploadFile(file);
          setSuccessfulUploads((prev) => prev + 1);
        } catch (error) {
          if (error instanceof UploadError) {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });

      await Promise.allSettled(uploadPromises);

      await refetchFiles();
      setisUploading(false);
    },

    [uploadFile, refetchFiles],
  );

  return { onUpload, isUploading, successfulUploads, handleRemoveFile, fileRemoving };
};

export default useUploadFiles;
