export const getAPIBaseChronos = () => {
  const environment = process.env.REACT_APP_WEXLER_ENV;
  switch (environment) {
    case 'development':
      return 'https://tc2rqni3gd.eu-west-2.awsapprunner.com';
    case 'production':
      return 'https://ctty2sm3ct.eu-west-2.awsapprunner.com';
    case 'test':
      return 'https://sji3bf4n3n.eu-west-2.awsapprunner.com';
    case 'local':
      return 'http://localhost:8081';
    default:
      return 'http://localhost:8081';
  }
};

export const APIBaseChronos = getAPIBaseChronos();
