import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { Run } from 'types';

import DateCell from './DateCell';

export const columnLabels: Record<string, string> = {};

const useGetDocumentColumns = () => {
  const columns = useMemo<ColumnDef<Run, any>[]>(
    () => [
      {
        header: () => <div className="">Run</div>,
        size: 50,
        accessorKey: 'item_number',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { item_number },
            },
          },
        }: any) => <div className="flex w-10 flex-row items-center justify-start pl-2">{item_number + 1}</div>,
      },
      {
        header: () => <div className="">Status</div>,
        accessorKey: 'status',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { status, inQueue, queuePosition },
            },
          },
        }: any) => (
          <div className="w-44">{inQueue ? `In queue, position ${queuePosition}` : PIPELINE_STATUS[status]?.label}</div>
        ),
      },
      {
        id: 'completedAtEstimate',
        header: () => <div className="min-w-[150px]">Estimated End Time</div>,
        accessorKey: 'completedAtEstimate',
        enableColumnFilter: false,
        cell: ({ row }) =>
          row.original.status !== 'complete' ? (
            <DateCell format={'DD MMMM YYYY'} date={row.getValue('completedAtEstimate')} />
          ) : (
            ''
          ),
      },
    ],

    [],
  );
  return columns;
};

export default useGetDocumentColumns;
