import React, { useState } from 'react';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import formatDate from 'helpers/formatDate';
import { Matter } from 'types';

export interface MatterItemComponentProps {
  matter: Matter;
  isCreator: boolean;
  isSuperAdmin: boolean;
  onEdit: (id: string) => void;
  onView: (id: string) => void;
}

const MatterItemComponent = ({ matter, isCreator, onEdit, onView, isSuperAdmin }: MatterItemComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage text expansion

  return (
    <div className="flex w-full flex-col items-start justify-start rounded-md border border-gray-200 px-4 py-3 text-gray-800 shadow-sm">
      <div className="flex w-full flex-row items-center justify-between">
        {matter.name && <div className="text-sm font-semibold not-italic">{matter.name}</div>}
        <div className="right-0 flex items-center justify-between overflow-hidden text-xs font-semibold not-italic leading-5 text-green-700">
          Created: {formatDate(matter.createdDate)}
        </div>
      </div>
      {matter.code && <div className="text-xs not-italic text-gray-500">{matter.code}</div>}
      {matter.description && (
        <button
          className={`overflow-hidden text-left text-xs font-normal not-italic leading-4 ${
            isExpanded ? '' : 'line-clamp-3'
          }`}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {matter.description}
        </button>
      )}

      <div className="flex w-full items-center justify-between">
        <div className="mt-3 flex flex-row gap-4">
          <Button rounded="base" size="xs" text="View" type="primary" onClick={() => onView(matter.id)} />

          {(isCreator || isSuperAdmin) && (
            <Button
              rounded="base"
              size="xs"
              text="Edit"
              type="secondary"
              onClick={() => onEdit(matter.id)}
              icon={<FontAwesomeIcon icon={faPencil} className="mr-2 text-gray-800" />}
            />
          )}
        </div>
        {matter.editedDate && (
          <div className="mt-3 flex items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs font-normal italic leading-4">
            {`Last updated ${matter.editedBy?.email ? `by ${matter.editedBy?.email}` : ''} on ${formatDate(
              matter.editedDate,
              true,
            )}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default MatterItemComponent;
