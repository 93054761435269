import { memo, useCallback, useState } from 'react';

import { useDeleteChronology } from 'api/mutations/useDeleteChronology';
import { useUpdateChronology } from 'api/mutations/useUpdateChronology';
import Button from 'components/atoms/Button';
import EditableField from 'components/molecules/EditableField';
import ConfirmationModal from 'components/molecules/Modals/Confirmation';
import formatDate from 'helpers/formatDate';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chronology } from 'types';

const ChronologyListItem = memo(({ chronology }: { chronology: Chronology }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [currentName, setCurrentName] = useState(chronology.title || '');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { mutate: updateChronologyName, isLoading: isLoadingUpdateChronologyName } = useUpdateChronology({
    chronologyId: chronology.id,
    caseId: chronology.caseId,
  });
  const { mutate: deleteChronology, isLoading: isDeleting } = useDeleteChronology({
    chronologyId: chronology.id,
    caseId: chronology.caseId,
  });

  const onChronologyClick = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '1');
    searchParams.set('chronologyId', chronology.id);
    searchParams.set('title', currentName);
    navigate('/app/chronos/case-editor/data/chronology/view?' + searchParams.toString());

    // eslint-disable-next-line
  }, [chronology.id, currentName]);

  const onEditSave = (updatedValue: string) => {
    setCurrentName(updatedValue);
    if (updatedValue !== chronology.title) {
      setEditMode(false);
      updateChronologyName(updatedValue);
    }
  };

  const handleDeleteChronology = async () => {
    await deleteChronology();
    setDeleteModalOpen(false);
  };

  const lastUpdatedDate = chronology.lastEditedDate || chronology.createdDate;
  return (
    <div
      className="mb-3 flex w-full flex-col items-start justify-start rounded-lg border px-6 py-4 text-sm shadow"
      key={chronology.id}
    >
      <div className="font-semibold not-italic">
        <EditableField
          value={chronology.title}
          onFieldClick={onChronologyClick}
          isLoading={isLoadingUpdateChronologyName}
          onSave={onEditSave}
        />
      </div>
      <div className="right-0 mt-1 flex items-center justify-between overflow-hidden text-sm not-italic leading-5 text-green-700">
        Created: {formatDate(chronology.createdDate)}
      </div>

      <div className="mt-2 flex w-full gap-4">
        <Button text="View" type="primary" size="xs" rounded="md" onClick={onChronologyClick} disabled={editMode} />
        <Button
          text="Delete chronology"
          type="delete"
          size="xs"
          rounded="md"
          onClick={() => setDeleteModalOpen(true)}
        />
        {chronology.lastEditedBy && (
          <div className="ml-auto flex items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs italic text-gray-800">
            Last updated by {chronology.lastEditedBy.email} on {formatDate(lastUpdatedDate)}
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={deleteModalOpen}
        title="Delete chronology"
        description="Are you sure you want to delete this chronology?"
        onConfirm={handleDeleteChronology}
        isLoading={isDeleting}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </div>
  );
});

export default ChronologyListItem;
