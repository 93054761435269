import React, { useRef, useState } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import useCreateMatter from 'api/mutations/useCreateMatter';
import useOrgUsers from 'api/queries/useGetOrgUsers';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { MyOptionType } from 'types';
import { OrgUserOption } from 'types';

import CreateMatterButton from './components/CreateMatterButton';
import TextAreaWithLabel from './components/TextAreaWithLabel';
import UserList from './components/UserList';

const MatterCreator: React.FC = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<OrgUserOption[]>([]);
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaDescriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaMatterCodeRef = useRef<HTMLTextAreaElement | null>(null);
  const authInfo = useAuthInfo();

  const onSuccess = () => {
    toast.success('Matter created successfully');
    navigate(`/app/chronos/matters?page=0`);
  };

  const { data: orgUsers } = useOrgUsers();
  const { mutate: createMatter, isLoading: isLoadingCreateMatter } = useCreateMatter(onSuccess);

  const handleChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCode(e.target.value);
  };

  const handleCreateMatter = () => {
    createMatter({
      name,
      description,
      code,
      users: selectedUsers,
    });
  };

  const handleClickBack = () => {
    navigate(`/app/chronos/explorer/matters`);
  };

  const handleRemoveUser = (userId: string) => {
    const indexToRemove = selectedUsers.findIndex((x) => x.id === userId);
    const arrayCopy = [...selectedUsers];
    arrayCopy.splice(indexToRemove, 1);
    setSelectedUsers(arrayCopy);
  };

  const handleChangeSelectedUsers = (userToAdd: MyOptionType) => {
    const newSelectedUsers = [
      ...selectedUsers,
      {
        id: userToAdd?.value || '',
        email: userToAdd?.label || '',
      },
    ];
    setSelectedUsers(newSelectedUsers);
  };

  const canCreateMatter = name && description && selectedUsers;

  const userId = authInfo?.user?.userId;
  const selectedIds = selectedUsers.map((x) => x.id);
  const orgUsersOptions = (orgUsers ?? [])
    .filter((u) => !selectedIds.includes(u.id) && u.id !== userId)
    .map((u) => ({
      value: u.id,
      label: u.email,
    }));

  return (
    <div className="relative flex w-full flex-row overflow-auto text-white">
      <div className="flex w-full flex-col items-start justify-start pl-8">
        <div className="mt-4 flex flex-row items-center justify-start">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="mr-3 h-6 w-6 cursor-pointer text-black"
            onClick={handleClickBack}
          />
          <div className="w-full text-2xl font-bold not-italic text-black">New Matter</div>
        </div>
        <div className="" style={{ width: '690px' }}>
          <TextAreaWithLabel ref={textAreaNameRef} value={name} onChange={handleChangeName} label="Name of Matter" />
          <TextAreaWithLabel
            ref={textAreaDescriptionRef}
            value={description}
            onChange={handleChangeDescription}
            label="Description"
          />
          <TextAreaWithLabel
            ref={textAreaMatterCodeRef}
            value={code}
            onChange={handleChangeCode}
            label="Matter Code (optional)"
          />

          <div className="mt-8 w-full text-lg font-semibold not-italic text-black">Members ({selectedIds.length})</div>

          <Select
            className="mt-2 w-full rounded-lg outline-none"
            styles={reactSelectStylesCaseCreator}
            onChange={handleChangeSelectedUsers}
            options={orgUsersOptions}
            value={[]}
            placeholder="Select team members to include"
          />
          <UserList users={selectedUsers} onRemove={handleRemoveUser} />
        </div>
        <div className="mt-8">
          <CreateMatterButton
            isLoading={isLoadingCreateMatter}
            isDisabled={!canCreateMatter}
            onClick={handleCreateMatter}
            text={'Create Matter'}
          />
        </div>
      </div>
    </div>
  );
};

export default MatterCreator;
