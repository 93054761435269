export interface Doc {
  author: string;
  caseId: string;
  createdDate: Date;
  date: Date;
  dateText: string;
  errorInfo: { processingError: null; textExtractError: null; factUnderstandError: null };
  factsCount: number | null;
  file: File;
  fileId: string;
  id: string;
  isProcessed: boolean;
  isSubDoc: boolean;
  isSupplementary: boolean;
  runId: string;
  summary: string;
  type: string;
}

export interface HighlightCoordinate {
  index: number;
  id: string;
  height: number;
  left: number;
  pageIndex: number;
  top: number;
  width: number;
}

export interface Coordinate {
  coordinate_id: string;
  fact_id: string;
  run_id: string;
  width_c: number;
  height_c: number;
  left_c: number;
  top_c: number;
  n_page: number;
}

export interface ChronosDocumentCoordinate {
  event_id: string;
  doc_id: string;
  file_name: string;
  file_extension: string;
  coordinate_details: Coordinate[];
  document_type?: string;
  document_date_text?: string;
}

export interface ChronologyFact {
  id: string;
  chronologyId: string;
  factId: string;
  createdDate: Date;
  description: string;
  topic: string;
  author: string;
  factDate: Date;
  factDateText: string;
  relevance: Relevance;
  significance: string | null;
  classification: Classification;
  fact: Fact;
  locations: Location[];
  dateInfo: string | null;
  isHidden: boolean;
  verifiedById: string | null;
  verifiedBy: OrgUser | null;
  verifiedDate: Date | null;
  comments: FactComment[];
}

export interface Chronology {
  id: string;
  caseId: string;
  title: string;
  createdDate: Date;
  createdById: string;
  isRemoved: boolean;
  lastEditedById: string;
  lastEditedDate: Date | null;
  createdBy: OrgUser;
  lastEditedBy: OrgUser;
}

export interface Run {
  id: string;
  caseId: string;
  createdDate: Date;
  createdById: string;
  isUpdateNewDocuments: boolean;
  isUpdateCaseDetails: boolean;
  completedAtEstimate: Date;
  completedAt: Date;
  inQueue: boolean;
  queuePosition: number | null;
  queueEstimateDatetime: Date | null;
  successfulPagesCount: number;
  failedPagesCount: number;
  status: string;
}

export interface Case {
  id: string;
  matterId: string;
  name: string;
  s3FolderPath: string | null;
  type: string;
  legalIssues: string;
  parties: string;
  keyContext: string;
  keyTimePeriod: string;
  disputeStatus: string;
  isRemoved: boolean;
  createdById: string;
  createdDate: Date;
  editedById: string | null;
  editedDate: Date | null;
  runs: Run[];
  matter: Matter;
  createdBy: OrgUser | null;
  editedBy: OrgUser | null;
}

export interface OrgUser {
  id: string;
  email: string;
  disabled: boolean;
  organisationId: string;
  hasBeenActive: boolean;
  completedGuides: string[];
}
export type OrgUserOption = Omit<OrgUser, 'organisationId' | 'disabled' | 'hasBeenActive' | 'completedGuides'>;

export interface OrgUserWithMetadata extends OrgUser {
  metadata: User;
}

export interface Matter {
  id: string;
  organisationId: string;
  name: string;
  description: string;
  code: string;
  createdById: string;
  createdDate: Date;
  editedById: string | null;
  editedDate: Date | null;
  createdBy: OrgUser;
  editedBy: OrgUser | null;
}

export interface MatterEdit {
  id: string;
  organisationId: string;
  name: string;
  description: string;
  code: string;
  createdById: string;
  createdDate: Date;
  editedById: string | null;
  editedDate: Date | null;
  users: OrgUser[];
}

export interface Subquestion {
  answer: string;
  question: string;
}

export interface Summary {
  name: string;
  content: string;
  subquestions: Subquestion[];
}

export enum ReferenceType {
  CHUNK = 'chunk',
  FACT = 'fact',
}

export interface Reference {
  [key: string]: {
    type: ReferenceType;
    id: string;
  };
}

export type MyOptionType = {
  label: string;
  value: string;
  disabled?: boolean;
} | null;

export type IsMulti = false;

export type KEY_VALUE_TYPE = {
  [key: string]: {
    value: string;
    label: string;
  };
};

export interface AdminUser {
  userId: string;
  email: string;
  access_level: string;
}

export interface CheckState {
  isIncluded: boolean;
  isVerified: boolean;
  verified_by_email: string;
  verified_date: Date;
  first_comment: string;
  comments_count: number;
}

export interface FilterOption {
  value: string | boolean;
  label: string;
}

export interface Org {
  id: string;
  name: string;
}

export interface Metadata {
  analyticsAdmin?: string;
  wexlerAnalyticsAdmin?: string;
  orgSuperAdmin?: string;
}

export interface User {
  userId: string;
  email: string;
  emailConfirmed: boolean;
  hasPassword: boolean;
  pictureUrl: string;
  properties: {
    metadata: Metadata;
  };
  metadata: Metadata;
  locked: boolean;
  enabled: boolean;
  mfaEnabled: boolean;
  canCreateOrgs: boolean;
  createdAt: number;
  lastActiveAt: number;
  updatePasswordRequired: boolean;
}

export interface Filters {
  docIds: FilterOption[];
  relevance: FilterOption[];
  classification: FilterOption[];
  keyFacts: boolean;
}

export interface Chunk {
  caseId: string;
  id: string;
  content: string;
  docId: string;
  boundingBoxes: BoundingBox[];
  doc: Doc;
}

export interface PlanStage {
  name: string;
  description: string;
  parameters: PlanParameters[];
  status?: 'complete' | 'not-started' | 'in-progress';
}

export interface Option {
  label: string;
  value: string;
}

export type PlanParameters =
  | { name: string; type: 'str'; value: string | null; available_values: string[]; updated?: boolean }
  | { name: string; type: 'boolean'; value: boolean | null; available_values: string[]; updated?: boolean }
  | {
      name: string;
      type: 'List[single-select,fixed-list]';
      value: Option[] | null;
      available_values: Option[];
      updated?: boolean;
    }
  | {
      name: string;
      type: 'List[multi-select,fixed-list]';
      value: Option[] | null;
      available_values: Option[];
      all_selection?: boolean;
      updated?: boolean;
    }
  | {
      name: string;
      type: 'List[multi-select,any-value]';
      value: Option[] | null;
      available_values: Option[];
      updated?: boolean;
    };

export type ParameterValue = string | boolean | Option[] | null;

export interface FetchUserActivityResponse {
  isActive: boolean;
}

export interface RecentActivityItem {
  caseId: string;
  caseName: string;
  createdDate: string;
  type: 'thread' | 'chronology' | 'run';
  link: string;
}

export interface UserAction {
  action: 'ask-kim' | 'explore-facts' | 'create-case' | 'finish-draft';
  matterId?: string;
  caseId?: string;
  matterName?: string;
  caseName?: string;
}

export interface FetchUserActionsResponse {
  userActions: UserAction[];
}

export enum GuideState {
  COMPLETED = 'completed',
  NOT_STARTED = 'not-started',
}

export interface CompletedGuideDict {
  [key: string]: GuideState;
}

export interface File {
  id: string;
  caseId: string;
  createdDate: Date;
  createdById: string;
  name: string;
  extension: string;
  size: number;
  s3FileURI: string | null;
  numberOfPages: number | null;
  height: number | null;
  width: number | null;
  fileHash: string;
}

export interface AllFiles {
  files: File[];
  pageCount: number;
}

export interface BoundingBox {
  left: number;
  top: number;
  width: number;
  height: number;
  pageNumber: number;
}

export interface Location {
  id: string;
  factId: string;
  docId: string;
  boundingBoxes: BoundingBox[];
  doc?: Doc;
}

export enum Relevance {
  YES = 'relevant',
  NO = 'notRelevant',
  MAYBE = 'maybeRelevant',
}

export enum Classification {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  FUTURE = 'future',
  LITIGATION = 'litigation',
}

export interface Fact {
  id: string;
  caseId: string;
  runId: string;
  createdDate: Date;
  description: string;
  author: string;
  factDate: Date;
  factDateText: string;
  relevance: Relevance;
  significance: string;
  topic: string;
  classification: Classification;
  isSupplementaryFact: boolean;
  sourceText: string;
  dateIsAmbiguous: boolean;
  dateIsAmbiguousText: string | null;
  dateInfo: string | null;
  included: boolean;
  locations: Location[];
  comments: FactComment[];
}

export interface FactWithHighlights extends Fact {
  highlights?: HighlightCoordinate[];
}

export interface FactComment {
  id: string;
  factId: string;
  createdDate: Date;
  createdById: string;
  content: string;
  createdBy: OrgUser;
}

export interface DocIds {
  id: string;
  file: {
    name: string;
  };
}

export interface RelevantFactsByDoc {
  documentId: string;
  count: number;
  fileName: string;
}

export enum MessageType {
  User = 'user',
  Assistant = 'assistant',
  Internal = 'internal',
}

export interface ThreadObject {
  id: string;
  name: string;
  status: ThreadStatus;
  messages: ThreadMessage[];
  caseId: string;
  createdDate: Date;
  isRemoved: boolean;
  activeMessageId: string;
}

export type ConfidenceLevelText = 'High' | 'Medium' | 'Low' | 'Unknown';
export interface ConfidenceLevelObject {
  borderColor: string;
  backgroundColor: string;
  tooltipColor: string;
  textColor: string;
  text: ConfidenceLevelText;
  tooltip: string;
}

export type MessageTrafficLight = 'red' | 'amber' | 'green';

export interface MessageConfig {
  trafficLight?: MessageTrafficLight;
  flowType?: KimMode;
  flowName?: string;
  artifacts?: Artifact[];
  followUpQuestions?: string[];
  plan?: PlanStage[];
  docIds?: string[];
  draftType?: DraftType | null;
}

export interface ThreadMessage {
  id: string;
  threadId: string;
  type: MessageType;
  content: string;
  facts: Fact[];
  chunks: Chunk[];
  config: MessageConfig | null;
  createdDate: Date;
  createdById: string;
  references: Record<string, any> | null;
  executionState: Record<string, any> | null;
}

export interface Artifact {
  extension: string;
  file_name: string;
  s3_bucket: string;
  s3_key: string;
}

export enum KimMode {
  QuestionFlow = 'question_flow',
  ExtractionFlow = 'extraction_flow',
  DraftFlow = 'draft_flow',
}
export type DraftType = 'claim' | 'rebuttal';
export type ExecuteKimSearch = (args: { question: string; draftType?: DraftType | null }) => void;

export enum ThreadStatus {
  NotStarted = 'notStarted',
  PlanLoading = 'planLoading',
  PlanLoaded = 'planLoaded',
  PlanExecuting = 'planExecuting',
  PlanComplete = 'planComplete',
  Failed = 'failed',
}
